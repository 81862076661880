.c-header {
    font-size: $base-font-size;
    line-height: 1;
    border-top: 2px solid $primary-color;

    &__body {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-width: $page-base-width;
        margin: 0 auto;
        padding: 0 40px;

        @include mq(medium, max, true) {
            min-width: 100%;
            padding: calc(40 / 750 * 100vw) 10px;
        }
    }

    &__title {
        a {
            img {
                display: block;
                width: 300px;
                height: auto;
                transition: .3s ease-in-out;

                @include mq(medium, max, true) {
                    width: calc(400 / 750 * 100vw);
                }
            }

            &:hover {
                img {
                    @include mq {
                        transform: scale(1.1);
                        opacity: .7;
                    }
                }
            }
        }
    }

    &__nav {
        display: block;
        @include mq(medium, max, true) {
            display: none;
            position: absolute;
            top: 100%;
            left: 0;
            z-index: 100;
        }
    }

	&__spbtn {
		display: none;

		@include mq(medium, max, true) {
            display: block;
            position: relative;
            margin: 0 10px 0 0;
			width: calc(70 / 750 * 100vw);
            height: calc(60 / 750 * 100vw);
            background: none;
            border: 0;
            cursor: pointer;
        }
        
        &:active,
        &:visited,
        &:focus {
            outline: 0;
        }

		span {
            position: absolute;
            display: block;
            width: 100%;
            height: 2px;
            top: 0;
            left: 0;
            background-color: $darkest-color;
            border-radius: 10px;

            &:nth-child(1) {
                animation: menu-bar01 .75s forwards;
            }

            @keyframes menu-bar01 {
                0% {
                    transform: translateY(calc(30 / 750 * 100vw)) rotate(45deg);
                }
                50% {
                    transform: translateY(calc(30 / 750 * 100vw)) rotate(0);
                }
                100% {
                    transform: translateY(0) rotate(0);
                }
            }
            
            &:nth-child(2) {
                top: 50%;
                margin-top: -1px;
                transition: all .25s .25s;
            }
            
            &:nth-child(3) {
                width: 60%;
                top: auto;
                bottom: 0;
                animation: menu-bar02 .75s forwards;
            }

            @keyframes menu-bar02 {
                0% {
                    width: 100%;
                    transform: translateY(calc(30 / 750 * -100vw + 1px)) rotate(-45deg);
                }
                50% {
                    width: 60%;
                    transform: translateY(calc(30 / 750 * -100vw + 1px)) rotate(0);
                }
                100% {
                    width: 60%;
                    transform: translateY(0) rotate(0);
                }
            }
        }

        &[data-open="true"] {
            span {
                background-color: $primary-color;
                
                &:nth-child(1) {
                    animation: active-menu-bar01 .75s forwards;
                }
    
                @keyframes active-menu-bar01 {
                    0% {
                        transform: translateY(0) rotate(0);
                    }
                    50% {
                        transform: translateY(calc(30 / 750 * 100vw)) rotate(0);
                    }
                    100% {
                        transform: translateY(calc(30 / 750 * 100vw)) rotate(45deg);
                    }
                }
                
                &:nth-child(2) {
                    opacity: 0;
                }
                
                &:nth-child(3) {
                    width: 100%;
                    animation: active-menu-bar03 .75s forwards;
                }
    
                @keyframes active-menu-bar03 {
                    0% {
                        width: 60%;
                        transform: translateY(0) rotate(0);
                    }
                    50% {
                        width: 100%;
                        transform: translateY(calc(30 / 750 * -100vw + 1px)) rotate(0);
                    }
                    100% {
                        width: 100%;
                        transform: translateY(calc(30 / 750 * -100vw + 1px)) rotate(-45deg);
                    }
                }
            }
        }
	}
}
