// # 全般
// ========== ========== ========== ========== ==========
//
// サイトで最も明るい色
$lightest-color: #fff;
$lightest-color-translucent: rgba(255, 255, 255, 0.94);
// サイトで最も暗い色
$darkest-color: #222;
// メインカラー
$primary-color: #3e9d63;
$primary-color-lighter: lighten($primary-color, 30%);
$primary-color-darker: darken($primary-color, 30%);
$primary-color-gradation: linear-gradient(90deg, #479c65, #4ab177);
$primary-color-gradation-lighter: linear-gradient(0deg, #b2e4c6, #f2fbe8);
// サブカラー
$secondary-color: #969171;
$secondary-color-lighter: #baa98f;
$secondary-color-lighter2: #dad3c9;
$secondary-color-lighter3: #f6eee5;
// アクセントカラー
$accent-color: #f07100;
$accent-color-gradation: linear-gradient(90deg, #ff7800, #ff9f06);
// グレイカラー
$gray-color: #f0f0f0;
$gray-color02: #ededed;
$gray-color03: #ccc;
$gray-color04: #666;
$gray-color05: #333;
$gray-color06: #4d4d4d;
$gray-color07: #b2b2b2;
// 警告カラー
$warning-color: #ad1313;

// # タイポグラフィ
// ========== ========== ========== ========== ==========
//
// 標準のテキストの色
$font-primary-color: $darkest-color;
// 選択時の背景色（ブラウザデフォルト: #B3D4FF）
$selection-background-color: $primary-color-lighter;

// # ボックスモデル
// ========== ========== ========== ========== ==========
//
// 標準の境界線の色
$border-primary-color: #d2cdcd;

// # フォーム
// ========== ========== ========== ========== ==========
//
// フォーム要素フォーカス時のグローカラー
$btn-glow-color: #0570c7;
