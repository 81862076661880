.c-nav-breadcrumb {
	font-size: 1.6rem;
	max-width: 1200px;
	margin: 0 auto;
	padding-left: $page-viewport-side-margin;
	padding-right: $page-viewport-side-margin;

	@media (--md-lte) {
		display: none;
	}

	ol {
		margin: 0;
		padding: 0;
		display: flex;
		justify-content: flex-start;

		> li {
			list-style: none;
			font-size: calc(14 / 16 * 1em);

			&:last-child a {
				::after {
					display: none;
				}
			}
		}
	}

	a {
		color: $secondary-color;
		display: inline-block;
		text-decoration: underline;

		::after {
			content: " > ";
			display: inline-block;
			margin: 0 15px 0 20px;
		}
	}

	&__item {
		display: inline-block;
		margin: 0;
		padding: 0;
	}

	&__item[data-breadcrumb="current"] {
		a {
			text-decoration: none;
		}
	}
}
