.c-page-home {
	font-size: $base-font-size;

	@include mq(medium, max, true) {
		font-size: calc(26 / 750 * 100vw);
	}

	&__header {
		width: 100%;
	}

	&__about {
		padding: 80px 0 0;
		
		@include mq(medium, max, true) {
			padding: calc(100 / 750 * 100vw) 0 0;
		}

		&-body {
			width: 100%;
			margin: 0 auto;
		}

		&-statement {
			display: flex;
			flex-direction: row-reverse;
			justify-content: space-between;
			position: relative;
			width: $page-base-width;
			margin: 0 auto;
			z-index: 1;

			@include mq(medium, max, true) {
				flex-wrap: wrap;
				flex-direction: column-reverse;
				width: 100%;
				padding: 0 0 calc(80 / 750 * 100vw);
			}

			&-item {
				&[data-item="text"] {
					width: 480px;

					@include mq(medium, max, true) {
						width: 100%;
						padding: 0 $page-viewport-side-margin;
						margin-top: -10vw;
					}

					h2 {
						position: relative;
						margin: 0 0 .8em;
						padding: 40px 0 0;
						font-size: 3rem;
						font-weight: 500;
						line-height: 1.5;

						@include mq(medium, max, true) {
							padding: 2em $page-viewport-side-margin 0 0;
							font-size: calc(44 / 750 * 100vw);
							background-color: $lightest-color;
							z-index: 1;
						}

						&::after {
							content: "ABOUT";
							display: block;
							position: absolute;
							top: -10px;
							right: 0;
							color: $gray-color;
							font-size: 9rem;
							font-family: $font-family-montserrat;
							font-weight: 400;
							line-height: 1;
							letter-spacing: .05em;
							z-index: -1;

							@include mq(medium, max, true) {
								top: .4em;
								right: auto;
								left: -30px;
								font-size: calc(100 / 750 * 100vw);
								background-color: $lightest-color;
								z-index: -1;
							}
						}

						span {
							color: $primary-color;
						}
					}

					p {
						line-height: 2;
					}

					ul {
						margin: 0;
						padding: 0;

						li {
							position: relative;
							margin: 0 0 1em;
							padding: 1em 1.5em 1em 6em;
							list-style: none;
							border: 1px solid $gray-color02;
							font-size: 1.4rem;
							font-weight: 500;

							@include mq(medium, max, true) {
								font-size: calc(24 / 750 * 100vw);
							}

							&::before {
								content: "POINT";
								display: block;
								position: absolute;
								top: 50%;
								left: 1em;
								transform: translateY(-50%);
								padding: .5em 1em .45em;
								color: $primary-color;
								font-size: 1.1rem;
								font-family: $font-family-montserrat;
								font-weight: 400;
								line-height: 1;
								color: $lightest-color;
								background: $primary-color;
								background: $primary-color-gradation;
								border-radius: 3em;
								letter-spacing: .05em;

								@include mq(medium, max, true) {
									font-size: calc(18 / 750 * 100vw);
								}
							}
						}
					}
				}

				&[data-item="image"] {
					width: 460px;

					@include mq(medium, max, true) {
						width: 100%;
						padding: 0 0 0 $page-viewport-side-margin-lg;
					}

					img {
						max-width: 100%;
						box-shadow: 0 0 20px $gray-color02;

						@include mq(medium, max, true) {
							box-shadow: none;
						}
					}
				}
			}
		}

		&-feature {
			margin: -40px auto 0;
			padding: 90px 0 70px;
			width: 100%;
			min-width: $page-base-width;
			background-color: $gray-color02;

			@include mq(medium, max, true) {
				min-width: 100%;
				margin: 0 auto;
				padding: calc(80 / 750 * 100vw) $page-viewport-side-margin calc(100 / 750 * 100vw);
			}

			&-body {
				width: $page-base-width;
				margin: 0 auto;

				@include mq(medium, max, true) {
					width: 100%;
				}
			}

			&-title {
				margin: 0 0 2em;

				h3 {
					margin: 0;
					font-size: 2.6rem;
					text-align: center;
					line-height: 1;

					@include mq(medium, max, true) {
						font-size: calc(38 / 750 * 100vw);
					}
				}
			}

			&-list {
				display: flex;
				justify-content: space-between;
				margin: 0 0 40px;
				padding: 0;

				@include mq(medium, max, true) {
					flex-wrap: wrap;
					margin: 0 0 calc(80 / 750 * 100vw);
				}
			}

			&-item {
				width: 300px;
				list-style: none;

				@include mq(medium, max, true) {
					width: 100%;
					margin: 0 0 calc(60 / 750 * 100vw);
				}

				&:last-child {
					@include mq(medium, max, true) {
						margin: 0;
					}

				}
			}

			&-thumb {
				img {
					max-width: 100%;
				}
			}

			&-info {
				h4 {
					margin: .8em 0;
					font-size: 1.6rem;
					text-align: center;

					@include mq(medium, max, true) {
						font-size: calc(28 / 750 * 100vw);
					}
				}

				p {
					margin: 0;
					font-size: 1.4rem;

					@include mq(medium, max, true) {
						font-size: calc(24 / 750 * 100vw);
					}
				}
			}

			&-btn {
				text-align: center;

				a {
					display: inline-block;
					position: relative;
					padding: 1em 2em;
					min-width: 280px;
					font-weight: 300;
					color: $lightest-color;
					text-decoration: none;
					background: $gray-color05;
					border-radius: 3em;

					&::after {
						content: "";
						display: block;
						position: absolute;
						width: 8px;
						height: 8px;
						top: 50%;
						right: 1.5em;
						border-top: 2px solid $lightest-color;
						border-right: 2px solid $lightest-color;
						transform: translateY(-50%) rotate(45deg);
					}
				}
			}
		}
	}

	&__staff {
		width: 100%;
		min-width: $page-base-width;
		padding: 70px 0;
		background: url(../img/bg-staff.jpg) no-repeat center center;
		background-size: cover;
		background-attachment: fixed;

		@include mq(medium, max, true) {
			min-width: auto;
			padding: calc(100 / 750 * 100vw) 0;
			background-attachment: scroll;

		}

		&-body {
			margin: 0 auto;
		}

		&-title {
			margin: 0 0 1em;

			@include mq(medium, max, true) {
				margin: 0;
			}

			&-body {
				text-align: center;

				h2 {
					margin: 0;
					line-height: 1;

					span {
						display: block;

						&[data-lang="ja"] {
							margin: 0 0 .4em;
							font-size: 3.4rem;
							font-weight: 500;
							color: $primary-color;

							@include mq(medium, max, true) {
								font-size: calc(48 / 750 * 100vw);
							}
						}

						&[data-lang="en"] {
							font-size: 1.6rem;
							font-family: $font-family-montserrat;
							font-weight: 500;
							letter-spacing: .05em;

							@include mq(medium, max, true) {
								font-size: calc(26 / 750 * 100vw);
							}
						}

						
					}
				}
			}
		}

		&-slideshow {
			margin: 0 auto;
			padding: 0;
		}

		&-item {
			position: relative;
			width: 300px;
			height: 380px !important;
			margin: 25px;
			transition: .3s ease-in-out;
			box-shadow: 0 0 20px $gray-color03;

			@include mq(medium, max, true) {
				width: calc(450 / 750 * 100vw);
				height: calc(570 / 750 * 100vw) !important;
			}

			&[aria-hidden="true"] {
				transform: scale(0.9);
				opacity: .6;
				pointer-events: none;
			}

			&::before,
			&::after,
			a::before,
			a::after {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 0;
				height: 0;
				background-color: $primary-color;
				transition: 0.3s ease-in-out;
				z-index:1;
			}

			&::before {
				width: 0;
        		height: 3px;
			}

			&::after {
				top: auto;
				right: 0;
				bottom: 0;
				left: auto;
				width: 0;
        		height: 3px;
			}

			a {
				display: block;
				position: relative;
				width: 100%;
				height: 100%;
				background-color: $primary-color;

				&::before {
					width: 3px;
					height: 0;
				}

				&::after {
					top: auto;
					right: 0;
					bottom: 0;
					left: auto;
					width: 3px;
					height: 0;
				}
			}

			img {
				max-width: 300px;
				margin: 0 auto;
				transition: 0.3s ease-in-out;

				@include mq(medium, max, true) {
					max-width: 100%;
					width: 100%;
					height: 100%;
					object-fit: cover;
					font-family: 'object-fit: cover;';
				}
			}

			&-name {
				position: absolute;
				top: 1.2em;
				left: -.8em;
				transition: 0.4s ease-in-out;

				span {
					display: block;
					line-height: 1;

					&[data-lang="ja"] {
						margin-bottom: 10px;
						font-size: 2.4rem;
						font-weight: 500;
						color: $darkest-color;

						&:first-letter {
							color: $primary-color;
						}

						@include mq(medium, max, true) {
							font-size: calc(40 / 750 * 100vw);
						}
					}

					&[data-lang="en"] {
						font-size: 1.2rem;
						font-family: $font-family-montserrat;
						font-weight: 300;
						color: $gray-color03;
						letter-spacing: .05em;

						@include mq(medium, max, true) {
							font-size: calc(20 / 750 * 100vw);
						}
					}
				}
			}

			// &:hover {
			// 	&::before,
			// 	&::after {
			// 		@include mq {
			// 			width: 100%;
			// 		}
			// 	}

			// 	a {
			// 		&::before,
			// 		&::after {
			// 			@include mq {
			// 				height: 100%;
			// 			}
			// 		}
			// 	}

			// 	img {
			// 		@include mq {
			// 			opacity: .2;
			// 		}
			// 	}

			// 	.c-page-home__staff-item-name {
			// 		@include mq {
			// 			left: 1em;
			// 		}

			// 		span {
			// 			@include mq {
			// 				color: $lightest-color;
			// 			}

			// 			&[data-lang="ja"] {
			// 				&:first-letter {
			// 					@include mq {
			// 						color: $lightest-color;
			// 					}
			// 				}
			// 			}
			// 		}
			// 	}
			// }
		}

		.slick-prev,
		.slick-next {
			position: absolute;
			display: block;
			width: 80px;
			height: 80px;
			top: 50%;
			padding: 0;
			background-color: transparent;
			overflow: hidden;
			text-indent: 100%;
			border: 0;
			cursor: pointer;
			transition: .3s ease-in-out;
			transform: translateY(-50%) rotate(45deg);
			z-index: 10;
			outline: 0;
		}

		.slick-prev {
			left: 50px;
			border-bottom: 3px solid $gray-color05;
			border-left: 3px solid $gray-color05;

			&:hover {
				@include mq {
					border-color: $primary-color;
					left: 55px;
				}
			}

			
			
			@include mq(medium, max, true) {
				display: none !important;
			}
		}

		.slick-next {
			right: 50px;
			border-top: 3px solid $gray-color05;
			border-right: 3px solid $gray-color05;

			&:hover {
				@include mq {
					border-color: $primary-color;
					right: 55px;
				}
			}
			
			@include mq(medium, max, true) {
				display: none !important;
			}
		}

		&-btn {
			margin: 2em 0 0;
			text-align: center;

			a {
				display: inline-block;
				position: relative;
				padding: 1em 2em;
				min-width: 280px;
				font-weight: 300;
				color: $lightest-color;
				text-decoration: none;
				background: $gray-color05;
				border-radius: 3em;

				&::after {
					content: "";
					display: block;
					position: absolute;
					width: 8px;
					height: 8px;
					top: 50%;
					right: 1.5em;
					border-top: 2px solid $lightest-color;
					border-right: 2px solid $lightest-color;
					transform: translateY(-50%) rotate(45deg);
				}
			}
		}
	}

	&__menu {
		width: 100%;
		padding: 70px 0 50px;

		@include mq(medium, max, true) {
			padding: calc(100 / 750 * 100vw) 0 calc(40 / 750 * 100vw);
		}

		&-body {
			position: relative;
			width: $page-base-width;
			margin: 0 auto;

			@include mq(medium, max, true) {
				width: 100%;
			}
		}

		&-title {
			margin: 0 0 3em;

			@include mq(medium, max, true) {
				margin: 0 0 2em;
			}

			&-body {
				text-align: center;

				h2 {
					margin: 0;
					line-height: 1;

					span {
						display: block;

						&[data-lang="ja"] {
							margin: 0 0 .4em;
							font-size: 3.4rem;
							font-weight: 500;
							color: $primary-color;

							@include mq(medium, max, true) {
								font-size: calc(48 / 750 * 100vw);
							}
						}

						&[data-lang="en"] {
							font-size: 1.6rem;
							font-family: $font-family-montserrat;
							font-weight: 500;
							letter-spacing: .05em;

							@include mq(medium, max, true) {
								font-size: calc(20 / 750 * 100vw);
							}
						}

						
					}
				}
			}
		}

		&-container {
			position: relative;

			@include mq(medium, max, true) {
				padding: 0 $page-viewport-side-margin;
			}
			
			&::before {
				content: "";
				position: absolute;
				display: block;
				top: 120px;
				left: 30px;
				width: 940px;
				height: calc(100% - 120px);
				background: $primary-color-gradation-lighter;
				z-index: -1;

				@include mq(medium, max, true) {
					top: calc(150 / 750 * 100vw);
					left: 0;
					width: 100%;
					height: calc(100% - calc(150 / 750 * 100vw));
				}
			}
		}

		&-pickup-list,
		&-index-list {
			position: relative;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			margin: 0;
			padding: 0;
		}

		&-index-list {
			padding: 0 0 60px;

			@include mq(medium, max, true) {
				padding: 0 0 calc(100 / 750 * 100vw);
			}
		}

		&-pickup-item,
		&-index-item {
			margin: 0 0 50px;
			list-style: none;
		}

		&-pickup-item {
			width : 395px;

			&:first-child {
				margin: 0 25px 40px 80px;
			}

			&:last-child {
				margin: 0 80px 40px 25px;
			}

			@include mq(medium, max, true) {
				width: 100%;
				margin: 0 0 calc(50 / 750 * 100vw) !important;
			}
		}

		&-index-item {
			width: 300px;

			@include mq(medium, max, true) {
				width: 100%;
				margin: 0 0 calc(50 / 750 * 100vw);
			}

			&:last-child {
				@include mq(medium, max, true) {
					margin: 0;
				}
			}
		}

		&-pickup-thumb,
		&-index-thumb {
			position: relative;

			img {
				max-width: 100%;
			}
		}

		&-pickup-thumb {
			&::before {
				content: "Pickup!";
				position: absolute;
				display: block;
				top: -.3em;
				left: -.3em;
				transform: rotate(-15deg);
				color: $primary-color;
				font-size: 3rem;
				font-family:  $font-family-lobster;
			}
		}

		&-pickup-info,
		&-index-info {
			h3 {
				margin: .8em 0;
				font-size: 1.6rem;
				text-align: center;

				@include mq(medium, max, true) {
					font-size: calc(28 / 750 * 100vw);
				}
			}

			p {
				margin: 0;
				font-size: 1.4rem;

				@include mq(medium, max, true) {
					font-size: calc(24 / 750 * 100vw);
				}
			}
		}

		&-btn {
			text-align: center;
			transform: translateY(-50%);

			a {
				display: inline-block;
				position: relative;
				padding: 1em 2em;
				min-width: 280px;
				font-weight: 300;
				color: $lightest-color;
				text-decoration: none;
				background: $gray-color05;
				border-radius: 3em;

				&::after {
					content: "";
					display: block;
					position: absolute;
					width: 8px;
					height: 8px;
					top: 50%;
					right: 1.5em;
					border-top: 2px solid $lightest-color;
					border-right: 2px solid $lightest-color;
					transform: translateY(-50%) rotate(45deg);
				}
			}
		}
	}

	&__banner {
		padding: 0 0 70px;

		@include mq(medium, max, true) {
			padding: 0 $page-viewport-side-margin calc(100 / 750 * 100vw);
		}

		&-body {
			width: 940px;
			margin: 0 auto;

			@include mq(medium, max, true) {
				width: 100%;
			}
		}

		&-list {
			display: flex;
			justify-content: space-between;
			margin: 0;
			padding: 0;

			@include mq(medium, max, true) {
				flex-wrap: wrap;
			}
		}

		&-item {
			position: relative;
			width: 450px;
			list-style: none;
			border: 3px solid $gray-color02;

			@include mq(medium, max, true) {
				width: 100%;
				margin: 0 0 calc(30 / 750 * 100vw);
			}

			&:last-child {
				@include mq(medium, max, true) {
					margin: 0;
				}
			}

			&::before,
			&::after,
			a::before,
			a::after {
				content: '';
				position: absolute;
				top: -3px;
				left: -3px;
				width: -3px;
				height: -3px;
				background-color: $primary-color;
				transition: 0.3s ease-in-out;
				z-index:1;
			}

			&::before {
				width: 0;
        		height: 3px;
			}

			&::after {
				top: auto;
				right: -3px;
				bottom: -3px;
				left: auto;
				width: 0;
        		height: 3px;
			}

			a {
				position: relative;
				display: block;
				padding: 2em 0;
				text-align: center;
				text-decoration: none;
				color: $darkest-color;
				font-size: 1.7rem;
				font-weight: 500;

				@include mq(medium, max, true) {
					padding: calc(30 / 750 * 100vw) 0;
					font-size: calc(30 / 750 * 100vw);
				}

				&::before {
					width: 3px;
					height: 0;
				}

				&::after {
					top: auto;
					right: -3px;
					bottom: -3px;
					left: auto;
					width: 3px;
					height: 0;
				}
			}

			span {
				position: relative;
				display: block;
				padding: 50px 3em 0;
				transition: 0.3s ease-in-out;

				@include mq(medium, max, true) {
					padding: calc(80 / 750 * 100vw) 2em 0;
				}

				&::after {
					content: "";
					display: block;
					position: absolute;
					width: 14px;
					height: 14px;
					top: 50%;
					right: 1.5em;
					border-top: 3px solid $darkest-color;
					border-right: 3px solid $darkest-color;
					transform: translateY(-50%) rotate(45deg);
					transition: 0.3s ease-in-out;

					@include mq(medium, max, true) {
						width: 10px;
						height: 10px;
						top: 50%;
						right: 1.5em;
						border-top: 3px solid $darkest-color;
						border-right: 3px solid $darkest-color;
					}
				}
			}

			&:hover {
				
				&::before,
				&::after {
					@include mq {
						width: calc(100% + 6px);
					}
				}

				a {
					&::before,
					&::after {
						@include mq {
							height: calc(100% + 6px);
						}
					}
				}

				span {

					@include mq {
						color: $primary-color;
					}

					&::after {
						@include mq {
							right: 1.8em;
							border-color: $primary-color;
						}
					}
				}
			}

			&[data-item="voice"] {
				span {
					background: url("../img/icon-voice.png") no-repeat top center;
					background-size: 47px auto;

					@include mq(medium, max, true) {
						background-size: calc(70 / 750 * 100vw) auto;
					}
				}
			}

			&[data-item="faq"] {
				span {
					background: url("../img/icon-faq.png") no-repeat top center;
					background-size: 42px auto;

					@include mq(medium, max, true) {
						background-size: calc(70 / 750 * 100vw) auto;
					}
				}
			}
		}
	}

	&__social {
		width: 100%;
		min-width: $page-base-width;
		padding: 70px 0;
		background-color: $gray-color02;

		@include mq(medium, max, true) {
			min-width: 100%;
			padding: calc(100 / 750 * 100vw) $page-viewport-side-margin;
		}

		&-body {
			width: $page-base-width;
			margin: 0 auto;

			@include mq(medium, max, true) {
				width: 100%;
			}
		}

		&-title {
			margin: 0 0 3em;

			@include mq(medium, max, true) {
				margin: 0 0 1em;
			}

			&-body {
				text-align: center;

				h2 {
					margin: 0;
					line-height: 1;

					span {
						display: block;

						&[data-lang="ja"] {
							margin: 0 0 .4em;
							font-size: 3.4rem;
							font-weight: 500;
							color: $primary-color;

							@include mq(medium, max, true) {
								font-size: calc(48 / 750 * 100vw);
							}
						}

						&[data-lang="en"] {
							font-size: 1.6rem;
							font-family: $font-family-montserrat;
							font-weight: 500;
							letter-spacing: .05em;

							@include mq(medium, max, true) {
								font-size: calc(20 / 750 * 100vw);
							}
						}

						
					}
				}
			}
		}

		&-container {
			display: flex;
			justify-content: space-between;

			@include mq(medium, max, true) {
				flex-wrap: wrap;
			}
		}

		&-ekiten {
			width: 400px;
			margin: 0 50px;

			@include mq(medium, max, true) {
				width: 100%;
				margin: 0;
			}
		}

		&-facebook {
			width: 500px;
			text-align: center;

			@include mq(medium, max, true) {
				width: 100%;
			}
		}
	}

	&__info {
		padding: 70px 0;

		@include mq(medium, max, true) {
			padding: calc(100 / 750 * 100vw) $page-viewport-side-margin;
		}

		&-body {
			width: 940px;
			margin: 0 auto;

			@include mq(medium, max, true) {
				width: 100%;
			}
		}

		&-title {
			margin: 0 0 3em;

			@include mq(medium, max, true) {
				margin: 0 0 2em;
			}

			&-body {
				text-align: center;

				h2 {
					margin: 0;
					line-height: 1;

					span {
						display: block;

						&[data-lang="ja"] {
							margin: 0 0 .4em;
							font-size: 3.4rem;
							font-weight: 500;
							color: $primary-color;

							@include mq(medium, max, true) {
								font-size: calc(48 / 750 * 100vw);
							}
						}

						&[data-lang="en"] {
							font-size: 1.6rem;
							font-family: $font-family-montserrat;
							font-weight: 500;
							letter-spacing: .05em;

							@include mq(medium, max, true) {
								font-size: calc(20 / 750 * 100vw);
							}
						}

						
					}
				}
			}
		}

		&-list {
			margin: 0;
			padding: 0;
		}

		&-item {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			margin: 0 0 4em;
			list-style: none;

			&:last-child {
				@include mq(medium, max, true) {
					margin: 0;
				}
			}
		}

		&-thumb {
			width: 450px;

			@include mq(medium, max, true) {
				width: 100%;
			}

			img {
				max-width: 100%;
			}
		}

		&-detail {
			width: 450px;

			@include mq(medium, max, true) {
				width: 100%;
			}

			&-title {
				h3 {
					margin: 1em 0;
					font-size: 2rem;

					@include mq(medium, max, true) {
						margin: .7em 0;
						font-size: calc(32 / 750 * 100vw);
					}
				}
			}

			&-access {
				p {
					font-size: 1.5rem;

					@include mq(medium, max, true) {
						font-size: calc(26 / 750 * 100vw);
					}
				}

				[data-item="link-map"] {
					position: relative;
					margin: 0 0 0 .5em;
					font-size: 1.2rem;

					@include mq(medium, max, true) {
						display: inline-block;
						font-size: calc(22 / 750 * 100vw);
					}

					&::before {
						content: "";
						position: absolute;
						display: block;
						top: calc(50% - 1px);
						right: calc(-1em - 1px);
						width: 8px;
						height: 7px;
						border: 1px solid $primary-color;
						background-color: $lightest-color;
						transform: translateY(-50%);
						transition: 0.3s ease-in-out;
					}

					&::after {
						content: "";
						position: absolute;
						display: block;
						top: calc(50% + 1px);
						right: calc(-1em + 1px);
						width: 8px;
						height: 7px;
						border: 1px solid $primary-color;
						background-color: $lightest-color;
						transform: translateY(-50%);
						transition: 0.3s ease-in-out;
					}

					&:hover {

						@include mq {
							opacity: .7;
						}

						&::before,
						&::after {
							@include mq {
								opacity: .7;
							}
						}
					}
				}

				[data-item="tel"] {
					font-size: 2rem;
					font-weight: 500;
					font-family: $font-family-montserrat;
					text-decoration: none;
					color: $darkest-color;

					@include mq(medium, max, true) {
						font-size: calc(32 / 750 * 100vw);
					}

					&:hover {
						@include mq {
							color: $primary-color;
						}
					}
				}
			}

			&-time {
				padding: 1em 0;
				border-top: 1px solid $gray-color02;
				border-bottom: 1px solid $gray-color02;

				table {
					th,
					td {
						font-size: 1.4rem;
						font-family: $font-family-montserrat;

						@include mq(medium, max, true) {
							font-size: calc(26 / 750 * 100vw);
						}
					}

					th {
						width: 30%;
						text-align: center;
					}

					td {
						padding: 0 0 0 1em;
					}
				}
			}
		}

		&-conversion {
			width: 100%;
			margin: 2em 0 0;
			padding: 2em;
			background-color: $gray-color02;

			&-list {
				display: flex;
				justify-content: space-between;
				margin: 0;
				padding: 0;

				@include mq(medium, max, true) {
					flex-wrap: wrap;
				}
			}

			&-item {
				display: flex;
				justify-content: center;
				width: 430px;
				list-style: none;
				border-radius: 5px;

				@include mq(medium, max, true) {
					width: 100%;
				}

				a {
					position: relative;
					display: block;
					padding: 1.5em 3em;
					width: 100%;
					text-align: center;
					text-decoration: none;
				}

				&[data-item="ekiten"] {
					background-color: $lightest-color;

					@include mq(medium, max, true) {
						margin: 0 0 .7em;
					}
					
					a {
						display: flex;
						justify-content: center;
						align-items: center;
						font-size: 1.6rem;
						font-weight: 500;
						color: $darkest-color;

						@include mq(medium, max, true) {
							font-size: calc(26 / 750 * 100vw);
						}

						&::before {
							content: "";
							position: absolute;
							display: block;
							top: calc(50% - 2px);
							right: calc(1.5em - 2px);
							width: 11px;
							height: 10px;
							border: 1px solid $primary-color;
							background-color: $lightest-color;
							transform: translateY(-50%);
							transition: 0.3s ease-in-out;
						}
	
						&::after {
							content: "";
							position: absolute;
							display: block;
							top: calc(50% + 2px);
							right: calc(1.5em + 2px);
							width: 11px;
							height: 10px;
							border: 1px solid $primary-color;
							background-color: $lightest-color;
							transform: translateY(-50%);
							transition: 0.3s ease-in-out;
						}

						img {
							padding: 0 .5em 0 0;

							@include mq(medium, max, true) {
								width: calc(130 / 750 * 100vw);
								height: auto;
							}
						}
					}
					&:hover {
						@include mq {
							a{
								background: $gray-color03;
								border-radius: 5px;

								&::before {
									right: calc(1.8em - 2px);
									background: $gray-color03;
								}
	
								&::after {
									right: calc(1.8em + 2px);
									background: $gray-color03;
								}
							}
							
						}
					}
				}

				&[data-item="tel"] {
					background: $primary-color-gradation;

					a {
						background: url("../img/icon-tel.png") no-repeat 2em center;
						background-size: 20px auto;
						font-size: 1.6rem;
						font-weight: 500;
						font-family: $font-family-montserrat;
						color: $lightest-color;

						@include mq(medium, max, true) {
							padding: 1.5em 1em 1.5em 3em;
							background: url("../img/icon-tel.png") no-repeat 1em center;
							background-size: 15px auto;
							font-size: calc(22 / 750 * 100vw);
						}

						span {
							font-size: 2.2rem;

							@include mq(medium, max, true) {
								font-size: calc(36 / 750 * 100vw);
							}
						}
					}
				}
			}
		}
	}
}
