@import url("//fonts.googleapis.com/css?family=Montserrat:400,500,600,700");
@import url("//fonts.googleapis.com/css?family=Lobster");
@import url("//fonts.googleapis.com/earlyaccess/notosansjp.css");

/**
 * 游ゴシック体
 *
 */
 @font-face {
    font-family: YuGothicM;
    src: local("Yu Gothic Medium");
}

$font-family-yi-gothic: "YuGothic", "YuGothicM";

/**
 * ヘルベチカ
 *
 * OSにヘルベチカが搭載されている場合のハック対応
 * フォールバックはGoogle Fontの"Open Sans"推奨
 *
 */
@font-face {
    font-family: HelveticaHack;
    font-style: normal;
    font-weight: normal;
    src:
        local("HelveticaNeue"),
        local("Helvetica");
}

@font-face {
    font-family: HelveticaHack;
    font-style: normal;
    font-weight: bold;
    src:
        local("HelveticaNeue-Bold"),
        local("Helvetica-Bold");
}

$font-family-helvetica: "HelveticaHack", "Open Sans";

/**
 * 設定
 *
 */
 $font-family-primary: "Noto Sans JP", "Noto Sans", "Arial", sans-serif;
 $font-family-secondary: $font-family-primary;
 $font-family-tertiary: $font-family-primary;
 $font-family-number: $font-family-primary;
 $font-family-heading: $font-family-primary;
 $font-family-montserrat: "Montserrat", $font-family-primary;
 $font-family-lobster: "Lobster", cursive, $font-family-primary;
 $font-family-notosans: "Noto Sans JP", $font-family-primary;
