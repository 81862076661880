// stylelint-disable unit-blacklist

// # タイポグラフィ
//
// 10(base font size) / 16(Browser Default font size) = 0.625em * 100 = 62.5%
$root-font-size: 62.5%;
$base-font-size: 1.6rem; // 16pt base
$base-line-height: 1.8;
$indent-sub-list: 2em;

// # 寸法
//
$breakpoint-xs: 576px;
$breakpoint-sm: 768px;
$breakpoint-md: 1000px;
$breakpoint-lg: 1200px;
$page-viewport-width: $breakpoint-lg;
$page-base-width: $breakpoint-md;
$page-viewport-side-margin: 30px;
$page-viewport-side-margin-xs: 20px;
$page-viewport-side-margin-lg: 60px;

$mq-breakpoints: (
  'small' : $breakpoint-xs,
  'medium': $breakpoint-sm,
  'large' : $breakpoint-md,
  'xlarge': $breakpoint-lg,
);

@mixin mq($breakpoint: medium, $rule: min, $subtraction: false) {
  $breakpoint: map_get($mq-breakpoints, $breakpoint);
  @if $rule == max and $subtraction {
    $breakpoint: $breakpoint - 1;
  }
  @media screen and (#{$rule}-width: $breakpoint) {
    @content;
  }
}

// @custom-media --xs /* xsのみ */ screen and (max-width: $breakpoint-xs);
// @custom-media --sm /* smのみ */ screen and (min-width: $breakpoint-xs) and (max-width: resolve($breakpoint-sm - 1px));
// @custom-media --md /* mdのみ */ screen and (min-width: $breakpoint-sm) and (max-width: resolve($breakpoint-md - 1px));
// @custom-media --lg /* lgのみ */ screen and (min-width: $breakpoint-md) and (max-width: resolve($breakpoint-lg - 1px));
// @custom-media --xl /* xlのみ */ screen and (min-width: $breakpoint-lg);
// @custom-media --xs-lte /* xs以下 = xsのみ */ screen and (max-width: resolve($breakpoint-xs - 1px));
// @custom-media --sm-lte /* sm以下 */ screen and (max-width: resolve($breakpoint-sm - 1px));
// @custom-media --md-lte /* md以下 */ screen and (max-width: resolve($breakpoint-md - 1px));
// @custom-media --lg-lte /* lg以下 */ screen and (max-width: resolve($breakpoint-lg - 1px));
// @custom-media --xs-gt /* xs超え */ screen and (min-width: $breakpoint-xs);
// @custom-media --sm-gt /* sm超え */ screen and (min-width: $breakpoint-sm);
// @custom-media --md-gt /* md超え */ screen and (min-width: $breakpoint-md);
// @custom-media --lg-gt /* lg超え */ screen and (min-width: $breakpoint-lg);
// @custom-media --flex (max-width: resolve($page-base-width + $page-viewport-side-margin * 2));
// @custom-media --hr /* high resolution */ (min-resolution: 2dppx);

// # 汎用マージン
//
$margin-narrow-xs: 5px;
$margin-narrow-sm: 10px;
$margin-narrow-md: 10px;
$margin-narrow-lg: 15px;
$margin-narrow-xl: 15px;

$margin-wide-xs: 30px;
$margin-wide-sm: 50px;
$margin-wide-md: 50px;
$margin-wide-lg: 80px;
$margin-wide-xl: 80px;

// # カラム
//
$card-cols: 3;
$card-cols-sm: 3;
$card-cols-xs: 1;
$card-cols-side-margin: 30px;
$card-cols-side-sm-margin: 15px;
$card-cols-side-xs-margin: 0; // stylelint-disable-line length-zero-no-unit
$card-cols-vertical-margin: 40px;
$card-cols-vertical-sm-margin: 20px;
$card-cols-vertical-xs-margin: 30px;

// # メインカラムセンタリング
// @define-mixin flexible-width-body {
// 	width: $page-viewport-width;

// 	@media (max-width: resolve($page-viewport-width + $page-viewport-side-margin * 2)) {
// 		max-width: resolve($page-viewport-width + $page-viewport-side-margin * 2);
// 		padding-left: $page-viewport-side-margin;
// 		padding-right: $page-viewport-side-margin;
// 		width: 100%;
// 	}

// 	@media (--xs) {
// 		padding-left: $page-viewport-side-margin-xs;
// 		padding-right: $page-viewport-side-margin-xs;
// 	}
// }

// @define-mixin flexible-width-main-conntents {
// 	width: $page-base-width;

// 	@media (max-width: resolve($page-base-width + $page-viewport-side-margin * 2)) {
// 		max-width: resolve($page-base-width + $page-viewport-side-margin * 2);
// 		padding-left: $page-viewport-side-margin;
// 		padding-right: $page-viewport-side-margin;
// 		width: 100%;
// 	}
// }

// stylelint-enable unit-blacklist
