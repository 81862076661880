.c-hero {
	// ☝ slick.js で自動生成されるDOMを参考にする
	// 📝 http://kenwheeler.github.io/slick/
	text-align: center;

	&__body {
		position: relative;
		padding: 0 40px;

		@include mq(medium, max, true) {
			padding: 0;
		}
	}

	&__slideshow {
		display: block;
		width: 100%;
		height: calc(70/100 * 100vh);
		min-height: 500px;
		margin: 0;
		padding: 0;

		@include mq(medium, max, true) {
			height: calc(50/100 * 100vh);
			min-height: auto;
		}

		&-item {
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
  				font-family: 'object-fit: cover;';
			}
		}
	}

	.slick-list,
	.slick-track {
		height: 100%;
	}

	.slick-dots {
		display: flex;
		position: absolute;
		bottom: 30px;
		left: 50px;
		padding: 0;

		@include mq(medium, max, true) {
			bottom: 20px;
			left: 50%;
			transform: translateX(-50%);
		}

		li {
			padding: 0 15px 0 0;
			list-style: none;
			line-height: 1;

			&:last-child {
				padding: 0;
			}

			button {
				display: inline-block;
				width: 12px;
				height: 12px;
				padding: 0;
				border: 0;
				border-radius: 100%;
				background-color: $darkest-color;
				cursor: pointer;
				overflow: hidden;
				text-indent: 100%;
				transition: .3s ease-in-out;
				opacity: .5;

				&:hover {
					@include mq {
						background-color: $primary-color;
						opacity: 1;
						transform: scale(1.3);
					}
				}
			}

			&.slick-active {
				button {
					background-color: $primary-color;
					opacity: 1;
				}
			}
		}
	}

	%ctrl {
		appearance: none;
		display: block;
		position: absolute;
		top: 50%;
	}

	[aria-label="Previous"] {
		@extend %ctrl;
		left: 0;
		z-index: 10;
	}

	[aria-label="Next"] {
		@extend %ctrl;
		right: 0;
		z-index: 10;
	}

	[role="listbox"] {
		position: relative;
		z-index: 0;

		> div {
			float: left;
			position: relative;
			width: 100%;
			height: 100%;

			> div {
				position: relative;
				width: 100%;
				height: 0;
				padding-top: 54%;
				overflow: hidden;

				> img {
					display: block;
					width: 100%;
					height: auto;
					position: absolute;
					will-change: bottom;
				}
			}
		}
	}

	// stylelint-disable selector-class-pattern, selector-nested-pattern
	.slick-prev,
	.slick-next {
		background: $lightest-color;
		font-weight: bold;
		border: 1px solid transparent;
		border-radius: 50%;
		padding: 15px;
		margin: 0 10px;
		position: absolute;
		bottom: 45%;
		z-index: 100;
		outline: none;
	}

	.slick-prev {
		left: 0;
	}

	.slick-next {
		right: 0;
	} // stylelint-enable

	&__title {
		position: absolute;
		top: 50%;
		left: 0;
		width: 100%;
		transform: translateY(-50%);

		@include mq(medium, max, true) {
			top: 52%;
		}

		h1 {
			color: $lightest-color;

			span {
				display: block;
				text-shadow: 0 0 20px $darkest-color;
				
				&[data-lang="ja"] {
					font-size: 3.6rem;
					font-family: $font-family-notosans;
					font-weight: 300 !important;
					letter-spacing: .05em;

					@include mq(medium, max, true) {
						font-size: calc(52 / 750 * 100vw);
					}
				}

				&[data-lang="en"] {
					font-size: 1.6rem;
					font-family:  $font-family-montserrat;
					font-weight: 300 !important;
					letter-spacing: .1em;

					@include mq(medium, max, true) {
						font-size: calc(28 / 750 * 100vw);
					}
				}
			}
		}
	}

	&__ranking {
		position: absolute;
		top: 30px;
		right: 70px;
		width: calc(190/1400 * 100vw);
		min-width: 160px;

		@include mq(medium, max, true) {
			top: 10px;
			right: 10px;
			width: calc(200/750 * 100vw);
			min-width: auto;
			max-width: 130px;
		}

		a {
			&:hover {
				@include mq {
					opacity: .7;
				}
			}
		}

		img {
			width: 100%;
		}
	}
}
