body {
	color: $font-primary-color;
	font-family: $font-family-primary, sans-serif;
	font-size: $base-font-size;
	font-weight: 400;
	line-height: $base-line-height;
	letter-spacing: .05em;
	font-feature-settings: "palt";
	margin: 0;
	padding: 0;

	@include mq(medium, max, true) {
		font-size: calc(26 / 750 * 100vw);
	}

	&[data-scroll-prevent="true"] {
		position: fixed;
		z-index: -1;
		width: 100%;
		height: 100%;
	}

	a {
		color: $primary-color;
		transition: .3s ease-in-out;

		&[href^="tel"] {
			@include mq {
				pointer-events: none;
			}
		}
	}

	img {
		vertical-align: top;

		@include mq(medium, max, true) {
			width: 100%;
		}
	}
}

[data-visible="false"] {
	opacity: 0;
	transition: .7s ease-in-out;

	@include mq(medium, max, true) {
		transition: .45s ease-in-out;
	}
}

[data-visible="true"] {
	opacity: 1;
	transition: .6s ease-in-out;

	@include mq(medium, max, true) {
		transition: .45s ease-in-out;
	}
}
