.c-nav-global {
	@include mq(medium, max, true) {
		background: $lightest-color-translucent;
		height: 100vh;
	}

	&__body {
		display: flex;
		flex-direction: column-reverse;

		@include mq(medium, max, true) {
			flex-direction: row;
			flex-wrap: wrap;
		}
	}

	&__list1,
	&__list2 {
		ul {
			display: flex;
			justify-content: flex-end;
			align-items: center;

			@include mq(medium, max, true) {
				justify-content: flex-start;
				flex-wrap: wrap;
				margin: 0 !important;
				padding: 0;
			}

			li {
				list-style: none;

				@include mq(medium, max, true) {
					width: 100%;
					padding: 0 !important;
				}
			}

			a {
				text-decoration: none;

				@include mq(medium, max, true) {
					display: block;
					padding: 1.2em $page-viewport-side-margin !important;
					text-align: center;
					font-size: calc(32 / 750 * 100vw) !important;
					font-weight: 700;
				}
			}
		}
	}

	&__list1 {
		ul {
			margin-bottom: 1.5em;

			@include mq(medium, max, true) {
				margin-top: 1.2em !important;
			}

			li {
				position: relative;
				padding: 0 1.3em;

				@include mq(xlarge) {
					padding: 0 2.2em;
				}
	
				&::after {
					content: "";
					position: absolute;
					display: block;
					width: 1px;
					height: 100%;
					top: 0;
					left: 0;
					background-color: $gray-color03;

					@include mq(medium, max, true) {
						display: none;
					}
				}
	
				&:first-child {
					padding-left: 0;
	
					&::after {
						display: none;
					}
				}
	
				a {
					font-size: 1.45rem;
					font-weight: 500;
					color: $darkest-color;

					@include mq(xlarge) {
						font-size: 1.5rem;
					}
	
					&:hover {
						@include mq {
							color: $primary-color;
						}
					}
				}
			}
		}
	}

	&__list2 {
		// 二次リリース時、削除
		@include mq(medium, max, true) {
			width: 100%;
		}

		ul {
			margin-bottom: .5em;

			li {
				position: relative;
				padding: 0 1em;
	
				&:first-child {
					padding-left: 0;
				}
	
				&:last-child {
					padding-right: 0;
				}
	
				a {
					font-size: 1.4rem;
					font-weight: 500;
					color: $gray-color04;
	
					&:hover {
						@include mq {
							color: $primary-color;
						}
					}

					@include mq(medium, max, true) {
						color: $darkest-color;
					}
				}
	
				&[data-item="contact"] {
					@include mq(medium, max, true) {
						margin: 1.2em $page-viewport-side-margin;
					}

					a {
						display: block;
						position: relative;
						padding: 1em 4em;
						font-weight: 300;
						color: $lightest-color;
						background: $primary-color;
						// background: $primary-color-gradation;
						border-radius: 3em;
	
						&::after {
							content: "";
							display: block;
							position: absolute;
							width: 7px;
							height: 7px;
							top: 50%;
							right: 1.2em;
							border-top: 2px solid $lightest-color;
							border-right: 2px solid $lightest-color;
							transform: translateY(-50%) rotate(45deg);
							transition: .3s ease-in-out;
						}
	
						&:hover {
							@include mq {
								background: $accent-color;

								&::after {
									right: 1.5em;
								}
							}
						}
					}
				}
			}
		}
	}
}
