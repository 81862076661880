@keyframes pagetop {
	from {
		background-position: 0 0;
	}

	to {
		background-position: 0 -5060px;
	}
}

@keyframes pagetop-back {
	from {
		background-position: 0 -5060px;
	}

	to {
		background-position: 0 0;
	}
}

.c-footer {
    min-width: $page-base-width;
    background-color: $gray-color05;
    color: $lightest-color;

    @include mq(medium, max, true) {
        min-width: 100%;
    }

    &__pagetop {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;

		a {
			position: fixed;
			display: block;
			width: 100px;
			height: 115px;
			overflow: hidden;
			background: url("../img/icon-pegetop-step.png") no-repeat 0 0;
			background-size: 100% auto;
			bottom: 30px;
            right: 30px;
            z-index: 100;
			text-indent: -999px;
			transition: transform .3s;

            @include mq(medium, max, true) {
                bottom: 5px;
                right: 5px;
            }

			&[data-hide="true"] {
                transform: translateX(150px);
                animation: pagetop-back 3000ms steps(44, start) forwards;
			}

            &[data-hide="false"] {
                transform: translateX(0);
                animation: pagetop 3000ms steps(44, start) forwards;
            }

			&:hover {
                @include mq {
					opacity: .7;
				}
			}
		}
	}

    &__body {
        display: flex;
        justify-content: space-between;
        width: $page-base-width;
        margin: 0 auto;
        padding: 50px 0;

        @include mq(medium, max, true) {
            flex-wrap: wrap;
            width: 100%;
            padding: 0 0 calc(80 / 750 * 100vw);
        }
    }

    &__sitemap {
        width: 300px;

        @include mq(medium, max, true) {
            width: 100%;
            margin: 0 0 calc(60 / 750 * 100vw);
        }

        &-list {
            column-count: 2;
            width: 100%;
            margin: 0;
            padding: 0;

            @include mq(medium, max, true) {
                column-count: auto;
            }
        }

        &-item {
            margin: 0 0 .7em;
            list-style: none;

            @include mq(medium, max, true) {
                margin: 0;
            }

            a {
                font-size: 1.4rem;
                color: $lightest-color;
                text-decoration: none;
                transition: 0.3s ease-in-out;

                @include mq(medium, max, true) {
                    position: relative;
                    display: block;
                    padding: 1em $page-viewport-side-margin;
                    font-size: calc(26 / 750 * 100vw);
                    border-bottom: 1px solid $gray-color06;
                }

                &:hover {
					@include mq {
                        color: $primary-color;
                    }
                }

				&::after {
                    @include mq(medium, max, true) {
                        content: "";
                        display: block;
                        position: absolute;
                        width: 8px;
                        height: 8px;
                        top: 50%;
                        right: 1.5em;
                        border-top: 2px solid $lightest-color;
                        border-right: 2px solid $lightest-color;
                        transform: translateY(-50%) rotate(45deg);
                    }
				}
            }
        }
    }

    &__info {
        display: flex;
        justify-content: space-between;
        width: 540px;

        @include mq(medium, max, true) {
            flex-wrap: wrap;
            width: 100%;
        }

        &-item {
            width: 250px;

            @include mq(medium, max, true) {
                width: 100%;
                padding: 0 $page-viewport-side-margin;
                margin: 0 0 calc(60 / 750 * 100vw);
            }

            &:last-child {
                margin: 0;
            }
        }

        &-title {
            margin: 0 0 1em;
            padding: 0 0 1em;
            border-bottom: 1px solid $gray-color06;
            font-size: 1.5rem;
            font-weight: 500;

            @include mq(medium, max, true) {
                font-size: calc(32 / 750 * 100vw);
            }
        }

        &-detail {
            font-size: 1.4rem;
            color: $gray-color07;

            @include mq(medium, max, true) {
                font-size: calc(24 / 750 * 100vw);
            }

            a {
                &[data-item="tel"] {
                    font-size: 1.8rem;
                    font-family: $font-family-montserrat;
                    font-weight: 500;
                    color: $gray-color07;
                    text-decoration: none;

                    @include mq(medium, max, true) {
                        font-size: calc(36 / 750 * 100vw);
                        color: $lightest-color;
                    }
                }
            }
        }

        &-btn {
            position: relative;
			display: block;
			padding: 1.2em;
            width: 100%;
            font-size: 1.3rem;
            line-height: 1;
			text-align: center;
			text-decoration: none;
            color: $lightest-color;
            background-color: $gray-color06;
            border-radius: 5px;

            @include mq(medium, max, true) {
                padding: 1.8em;
                font-size: calc(24 / 750 * 100vw);
            }

            &::before {
                content: "";
                position: absolute;
                display: block;
                top: calc(50% - 2px);
                right: calc(1.5em - 2px);
                width: 10px;
                height: 9px;
                border: 1px solid $primary-color;
                background-color: $gray-color06;
                transform: translateY(-50%);
                transition: 0.3s ease-in-out;
            }

            &::after {
                content: "";
                position: absolute;
                display: block;
                top: calc(50% + 2px);
                right: calc(1.5em + 2px);
                width: 10px;
                height: 9px;
                border: 1px solid $primary-color;
                background-color: $gray-color06;
                transform: translateY(-50%);
                transition: 0.3s ease-in-out;
            }
	
            &:hover {
                @include mq {
                    background-color: $darkest-color;

                    &::before,
                    &::after {
                        background-color: $darkest-color;
                    }
                }
            }
        }
    }

    &__sns {
        &-list {
            display: flex;
            justify-content: center;
            margin: 0;
            padding: 0;
            text-align: center;
        }

        &-item {
            list-style: none;

            &:last-child {
                margin: 0 0 0 .5em;
            }

            a {
                display: inline-block;
                padding: .8em;
                border: 1px solid $lightest-color;
                border-radius: 100%;
                line-height: 1;
                transition: 0.3s ease-in-out;

                @include mq(medium, max, true) {
                    padding: 1em;
                }

                img {
                    width: 16px;
                    height: auto;

                    @include mq(medium, max, true) {
                        width: 25px;
                    }
                }

                &:hover {
					@include mq {
                        background-color: $primary-color;
                        border-color: $primary-color;
					}
                }
            }
        }
    }

    &__copyright {
        padding: 3em 0 5em;
        font-size: 1.2rem;
        text-align: center;
        color: $gray-color04;

        @include mq(medium, max, true) {
            padding: calc(60 / 750 * 100vw) $page-viewport-side-margin 100px;
            font-size: calc(26 / 750 * 100vw);
        }
    }
}
