// stylelint-disable selector-max-universal
// this is universal selector rules
* {
	&,
	&::before,
	&::after {
		box-sizing: border-box;
	}

	&::selection {
		background-color: $selection-background-color;
	}
}

// stylelint-enable selector-max-universal
