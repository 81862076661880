.c-page-sub {
	&__content {
		margin: 50px auto 80px;
	}

	&__content-title {
		margin-bottom: 50px;
	}

	&__breadcrumb {
		//
	}

	&__content-detail {
		margin: 0 auto;
		padding: 26px 0 80px;

		@media (--xs) {
			padding: 26px 0 80px;
		}
	}
}
